

























































































import { Vue, Component } from 'vue-property-decorator';
import data from './requests.json';
import CChartStacked from './c-content-chart-stacked';

@Component({
    components: {
        'c-chart-stacked': CChartStacked
    }
})
export default class RequestContent extends Vue {
    private data = data;
    private apparat: any[] = [];
    private regional: any[] = [];
    private totalDirection: any[] = [];
    private totalRegional: any[] = [];
    private totalChartDirect: any[] = [];
    private directionCompleted = 0;
    private directionInWork = 0;
    private directionUnfulfilled = 0;
    private directionWorkingControl = 0;
    private totalChartRegional: any[] = [];
    private regionalCompleted = 0;
    private regionalInWork = 0;
    private regionalUnfulfilled = 0;
    private regionalWorkingControl = 0;
    private directon() {
        let totalDirect = 0;
        let totalDirectCompl = 0;
        let totalDirectInWork = 0;
        let totalDirectNew = 0;
        let totalDirectUnfulfilled = 0;
        let totalDirectWorkingControl = 0;
        let totalReg = 0;
        let totalRegCompl = 0;
        let totalRegInWork = 0;
        let totalRegNew = 0;
        let totalRegUnfulfilled = 0;
        let totalRegWorkingControl = 0;
        data.forEach((datas: any) => {
            if (datas.type.toLowerCase() === 'руководство') {
                this.apparat.push(datas);
                totalDirect += datas.total;
                totalDirectCompl += datas.completed;
                totalDirectInWork += datas.open.inWork;
                totalDirectNew += datas.open.new;
                totalDirectUnfulfilled += datas.open.unfulfilled;
                totalDirectWorkingControl += datas.open.workingControl;
            } else if (datas.type.toLowerCase() === 'по городу и районам') {
                this.regional.push(datas);
                totalReg += datas.total;
                totalRegCompl += datas.completed;
                totalRegInWork += datas.open.inWork;
                totalRegNew += datas.open.new;
                totalRegUnfulfilled += datas.open.unfulfilled;
                totalRegWorkingControl += datas.open.workingControl;
            }
        });
        this.totalDirection.push(totalDirectCompl, totalDirectInWork, totalDirectUnfulfilled, totalDirectWorkingControl, totalDirectNew, totalDirect);
        this.totalRegional.push(totalRegCompl, totalRegInWork, totalRegUnfulfilled, totalRegWorkingControl, totalRegNew, totalReg);
        const directCompleted = (Math.round(((totalDirectCompl / totalDirect) * 100) * 10)) / 10;
        const directInWork = (Math.round(((totalDirectInWork / totalDirect) * 100) * 10)) / 10;
        const directUnfulfilled = (Math.round(((totalDirectUnfulfilled / totalDirect) * 100) * 10)) / 10;
        const directWorkingControl = (Math.round(((totalDirectWorkingControl / totalDirect) * 100) * 10)) / 10;
        this.totalChartDirect.push({ name: 'На рабочем контроле', data: [directWorkingControl] }, { name: 'Неисполнено', data: [directUnfulfilled] }, { name: 'В работе', data: [directInWork] }, { name: 'Исполнено', data: [directCompleted] });
        this.directionCompleted = directCompleted;
        this.directionInWork = directInWork;
        this.directionUnfulfilled = directUnfulfilled;
        this.directionWorkingControl = directWorkingControl;
        const regCompleted = (Math.round(((totalRegCompl / totalReg) * 100) * 10)) / 10;
        const regInWork = (Math.round(((totalRegInWork / totalReg) * 100) * 10)) / 10;
        const regUnfulfilled = (Math.round(((totalRegUnfulfilled / totalReg) * 100) * 10)) / 10;
        const regWorkingControl = (Math.round(((totalRegWorkingControl / totalReg) * 100) * 10)) / 10;
        this.totalChartRegional.push({ name: 'На рабочем контроле', data: [regWorkingControl] }, { name: 'Неисполнено', data: [regUnfulfilled] }, { name: 'В работе', data: [regInWork] }, { name: 'Исполнено', data: [regCompleted] });
        this.regionalCompleted = regCompleted;
        this.regionalInWork = regInWork;
        this.regionalUnfulfilled = regUnfulfilled;
        this.regionalWorkingControl = regWorkingControl;
    }
    private mounted() {
        this.directon();
    }
}
