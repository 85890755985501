




















import Class from './c-chart-stacked';
export default Class;
