import { Vue, Component, Prop } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек']
    }
});

@Component
export default class CChartStacked extends Vue {
    private options: any = {};

    @Prop({
        type: Array,
        required: true
    })
    private data!: any;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawTriggerChanged() {
        if (this.chart !== undefined) {
            this.chart.reflow();
        }
    }

    private formatDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric'
        });

        return format.format(date);
    }

    private quarterDate(date: Date): string {
        const format = Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'long'
        });

        return format.format(date);
    }

    private setOptions() {
        const colors = ['#416084', '#953735', '#257bb0', '#e46c0a', '#5a80ad', '#449fd8', '#c0504d', '#f79646', '#2c4058', '#195275', '#632523', '#984807'];
        const test: Highcharts.SeriesOptionsType[] = [];
        const dataLabel: any = {
            enabled: true,
            formatter: function() {
                return this.y + " %";
            }
        }
        this.options = {
            colors,
            chart: {
                type: 'bar',
                backgroundColor: 'transparent',
                height: 125
            },
            title: {
                text: ' '
            },
            xAxis: {
                visible: false
            },
            yAxis: {
                visible: false
            },
            credits: {
                enabled: false
            },
            legend: {
                reversed: true,
                itemStyle: {
                    color: '#9cb3ce',
                    fontWeight: '400',
                    fontSize: '.9rem',
                    textOverflow: 'null'
                }
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '{series.name}: {point.y}%'
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                bar: {
                    dataLabels: dataLabel
                }
            },
            series: this.data
        };
    }

    private chart: Highcharts.Chart | undefined;

    private dataChanged() {
        this.setOptions();
        if (this.chart === undefined) {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[3].value = 'overflow: visible';
        } else {
            this.chart = Highcharts.chart(this.$el as HTMLElement, this.options);
            this.$el.attributes[3].value = 'overflow: visible';
        }
    }

    private created() {
        this.$watch('data', this.dataChanged);
        this.$watch('redrawTrigger', this.redrawTriggerChanged);
    }

    private mounted() {
        this.dataChanged();
    }

    private beforeDestroy() {
        if (this.chart !== undefined) {
            this.chart.destroy();
        }
    }
}